@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
// Variables
$yellow: #ffcc00;
$blue: #005a91;
$grey: #5f717e;
$height: calc(100vh - 180px);

// Global styles

body {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: hidden;
  color: #484848;
  background-color: $blue;
}

// Layout

#Layout {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  .content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo {
    height: 110px;
    width: 110px;
    position: fixed;
    top: 1.5em;
    right: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    z-index: 99;
    img {
      width: auto;
      max-height: 70%;
    }
  }
  .error {
    color: red;
    text-align: center;
  }
}

// #Loader

#Loader {
  height: 100px;
  width: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Login with token

#Login {
  width: 400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  label {
    display: block;
    color: #fff;
  }
  input[type="text"] {
    display: block;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 3px;
    appearance: none;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
  }
  button {
    background: $blue;
    appearance: none;
    border: 0px;
    color: #fff;
    border-radius: 3px;
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
  }

  .error {
    margin-top: 3rem;
    text-align: center;
    color: red;
    font-size: 16px;
  }
}

#Meldungen {
  width: 100%;
  .header {
    position: absolute;
    top: 2rem;
    left: 2rem;
    font-size: 60px;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    z-index: 99;
    img {
      height: 60px;
      width: auto;
      margin-right: 10px;
    }
  }
  // Carousel manipulieren

  .carousel {
    width: 100%;
    .control-dots {
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;
      .dot {
        background: $blue;
      }
    }
    z-index: 0;
    .slide {
      background: transparent;
    }
  }
}

// Einzelne Meldungen

.meldung {
  background: $blue;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-items: stretch;
  height: $height;
  width: 100vw;
  height: 100vh;
  font-size: 40px;
  text-align: left;
  background-image: url("./assets/bg.svg");
  background-size: cover;
  .template {
    flex: 1;
    background: #fff;
    margin: 0rem 2rem;
    margin-bottom: 50px;
    margin-top: 150px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    justify-content: center;
    flex-direction: column;
    display: flex;
    &.colored {
      background: transparent;
      box-shadow: none;
      color: #fff;
      h1 {
        color: #fff;
      }
      .zeile .spalte {
        border: 0px;
      }
    }

    .cover {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .contain {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 50px;
      line-height: 55px;
    }

    img {
      width: auto !important;
      height: auto !important;
    }

    .zeile {
      box-sizing: border-box;
      display: grid;
      &.s-1 {
        grid-template-columns: 1fr;
        .text {
          font-size: 30px;
          padding-left: 200px;
          padding-right: 200px;
        }
      }
      &.s-2 {
        grid-template-columns: 1fr 1fr;
        .text {
          padding-left: 100px;
          padding-right: 100px;
        }
      }
      &.s-3 {
        grid-template-columns: 1fr 1fr 1fr;
      }

      p {
        margin: 1rem 0;
        line-height: 120%;
      }
      width: 100%;

      align-items: stretch;

      .spalte {
        align-items: center;
        border-right: 1px solid #eee;
        overflow: auto;
        .text {
          flex: 1;
          box-sizing: border-box;
          margin: 50px;
          line-height: 1.1;
        }
        iframe {
          top: 0;
          margin: 0;
          width: 100%;
        }
        .video {
          text-align: center;
          video {
            max-width: 100%;
            height: auto;
          }
        }

        table {
          font-size: 20px;
          width: 100%;
          max-width: 100%;
          td {
            padding: 0.5rem;
            background: #eee;
          }
          tr:nth-child(even) {
            td {
              background: #fff;
            }
          }
        }
      }
    }
  }
}

// Tickermeldung

.ticker {
  h1 {
    font-size: 60px;
    margin: 0;
    margin-right: 100px;
    padding: 25px;
    white-space: nowrap;
    color: red;
  }
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  font-size: 80px;
  position: absolute;
  bottom: 0px;
  position: absolute !important;
  width: 100%;
}

.z-1 .cover {
  height: $height;
  float: left;
  top: 0;
  left: 0;
}

.z-1 .contain {
  height: $height;
}

.offline {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: red;
  padding: 2rem;
  color: white;
  font-weight: bold;
}
